/* DevZrico's Portfolio file*/

/* Change this file to get your personal Porfolio */

/* Some Tips:
★ Place your images in "src/assets/images" path
★ Place your custom loading animations in assets/lottie
★ Set value to false to hide a section or an entire page
★ Get fontAwesomeClassnames from https://www.fontawesome.com
★ Set blogSection false to hide all kind of blogs
*/

/*
Some informations are kept unchanged according to component owners 
Credits: masterPortfolio(https://github.com/ashutosh1919/masterPortfolio)
         developerFolio(https://github.com/saadpasta/developerFolio)
*/

// Website related settings
// Which pages will be enabled

const pageEnabled = {
  splash: true, // Change this to false if you don't want Splash screen

  education: true, // Change this to false if you don't want education (degrees, certifications, competitiveSites) page

  projects: true, // Change this to false if you don't want projects (from github data) and publications page

  opensource: true, // Change this to false if you don't want opensource contribution (from github data) page

  hobbies: true, // Change this to false if you don't want hobbies page

  experience: true, // Change this to false if you don't want experience page

  contact: true, // Change this to false if you don't want contact page
};

// Routing with or without '#'
const routerValue =
  "HashRouter"; /* Supported values:
 					1. HashRouter(for github hosted user Pages)
 					2. BrowserRouter (for regular hosting)
 					*/

// Splash Screen

const splashScreen = {
  useLottie: true, // setting it false will show animation of src/components/loader
  animationFile:
    "splashAnimation" /* Supported Values
    					             1. splashAnimation
    					             2. email
    					             3. build
    					             4. landingPerson
    					             5. codingPerson
    					             6. geometricLoader
    					             7. starLoading
    					             8. ripple
    					             9. nightDay
    					             10. littleRobot
    					             11. poolChartLoading
    					             */,
  duration: 3500, // Set animation duration(milliseconds) as per your animation
};

/**************               Home Page                    ****************/

//SEO Related settings
const seo = {
  title: "Zahidul Sifat",
  description:
    "Zahidul Sifat is a Web Developer, Android Developer, Graphic Designer, Researcher and an Executive at Smart Technologies (BD) Ltd.",
  og: {
    title: "Zahidul Sifat Portfolio",
    type: "website",
    url: "https://www.zahidulsifat.me",
  },
};

//Home Page
const greeting = {
  title: "Zahidul Sifat",
  logo_name: "Zahidul Sifat",
  nickname: "<DevZrico/>",
  subTitle:
    "A passionate individual having experience of Graphics Design, building Android OS, Websites and Web Applications with JavaScript,  Reactjs,  Nodejs, React Native and some other cool libraries.",
  resumeLink:
    "https://drive.usercontent.google.com/download?id=1tYvlIHNd1c89vwdgF9Q9CJDa6txIwZvw&export=download",
  portfolio_repository: "https://github.com/zahidulsifat", //star-me-on-github button
  githubProfile: "https://github.com/zahidulsifat", //more projects button
};

const socialMediaLinks = [
  /* Your Social Media Links */
  //github: "https://github.com/ashutosh1919",
  //linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  //gmail: "ashutoshhathidara98@gmail.com",
  //gitlab: "https://gitlab.com/ashutoshhathidara98",
  //facebook: "https://www.facebook.com/laymanbrother.19/",
  //twitter: "https://twitter.com/zahidulsifat",
  //instagram: "https://www.instagram.com/zahidulsifat/",

  {
    name: "Github",
    link: "https://github.com/zahidulsifat",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Research Gate",
    link: "https://www.researchgate.net/profile/Md-Zahidul-Sifat",
    fontAwesomeIcon: "fa-researchgate", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Google Scholar",
    link: "https://scholar.google.com/citations?user=s_VYP9gAAAAJ&hl=en",
    fontAwesomeIcon: "fa-google-scholar", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/zahidulsifat/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
    //  },
    //  {
    //    name: "YouTube",
    //    link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
    //    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    //    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:zahidulsifat@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/zahidulsifat",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/zahidulsifat/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/zahidulsifat/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

// Skill Information

const skills = {
  display: true, // Set it true to display your skills
  data: [
    /*{
      title: "Terminal Based Tools",
      imageName:
        "TerminalImg" /* Available imageNames: 
      
      FullStackImg, 
      TerminalImg,  
      DesignImg, 
      AppDevelopImg, 
      CloudInfraImg,
      DataScienceImg.
      */
    /*skills: [
        "⚡ Writting simple scripts for terminal like Encrypter",
        "⚡ Created script based weather app by Python.",
        "⚡ I wish to write frameworks for modern GUI terminals.",
      ],
      softwareSkills: [
        {
          skillName: "Shell",
          fontAwesomeClassname: "simple-icons:shell",
          style: {
            color: "#F24E1E",
          },
        },
        /*{
          skillName: "Ruby",
          fontAwesomeClassname: "logos-ruby",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },*/
    /*{
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#FFD43B",
          },
        },
      ],
    }*/
    {
      title: "Data Science & AI",
      imageName: "DataScienceImg",
      skills: [
        "⚡ Fine tuning scalable models for various ML use cases",
        "⚡ Experience of Supervised ML projects",
        "⚡ Optimization of ML models for localization and numerical analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#ffde57",
          },
        },
      ],
    },
    {
      title: "Front End Development",
      imageName: "FullStackImg",
      skills: [
        "Font End Developer Who Wants To Explore Every Tech Stack",
        "⚡ Building simple website using HTML, CSS, JavaScript.",
        "⚡ Web application using  API, ReactJS",
        "⚡ Web application by using JavaScript, CSS and Open Ai",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "SCSS",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "ExpressJS",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "#F24E1E",
          },
        },
        {
          /*()
          skillName: "Mongoose",
          fontAwesomeClassname: "logos-mongodb",
          style: {
            color: "#E34F26",
          },
        */
        },
      ],
    },
    {
      title: "Android OS Development",
      imageName: "CloudInfraImg",

      /* Available imageNames: 
    
      FullStackImg, 
      TerminalImg,  
      DesignImg, 
      AppDevelopImg, 
      CloudInfraImg,
      DataScienceImg.
      */ skills: [
        "⚡ Googles SafetyNet's issues on custom android OS",
        "⚡ Android Os Porting, Bug Fixes, Testing",
        "⚡ Wish to create apps soon",
      ],
      softwareSkills: [
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#0089D6",
          },
        },
      ],
    },
    {
      title: "Reverse Engineering",
      imageName: "TerminalImg",
      skills: [
        "⚡ Usually I code from scratch, I also like to modify other's codes.",
        "⚡ I just take a cool project and recreate it enhancing new features!",
        "⚡ My recreated projects also have a fresh new UI",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#ffde57",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Andoid",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
      ],
    },
    {
      title: "UI/UX and Graphics Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:gimp",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF61F6",
          },
        },
        {
          skillName: "Webflow",
          fontAwesomeClassname: "simple-icons:webflow",
          style: {
            color: "#4285F4",
          },
        },
      ],
    },
  ],
};

const techStack = {
  // Skill Part
  display: false, // Set it to true to view skillbars of language
  title: "Proficiency",
  experience: [
    {
      Stack: "HTML", // Insert stack or technology or language you have experience in
      progressPercentage: "90%", // Insert relative proficiency in percentage
    },
    {
      Stack: "CSS, SASS",
      progressPercentage: "80%",
    },
    {
      Stack: "JavaScript",
      progressPercentage: "80%",
    },
    /*{
      Stack: "PHP",
      progressPercentage: "60%",
    },*/
    {
      Stack: "Python",
      progressPercentage: "80%",
    },
    /*{
      Stack: "Shell",
      progressPercentage: "80%",
    },
    {
      Stack: "Ruby",
      progressPercentage: "70%",
    },*/
    {
      Stack: "C, C++",
      progressPercentage: "60%",
    },
    {
      Stack: "Java",
      progressPercentage: "60%",
    },
    /*{
      Stack: "GO",
      progressPercentage: "60%",
     },
     {
      Stack: "CPP",
      progressPercentage: "50%",
    },*/
  ],
};

// Top Contributioned projects
const bigProjects = {
  display: false, // Set it true to show big projects
  title: "Big Projects",
  subtitle: "Some startups and companies that i helped to create their tech",
  projects: [
    {
      image: "saayaHealthLogo.png",
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/",
        },
        //  you can add extra buttons here.
      ],
    },
    {
      image: "nextuLogo.jpg",
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/",
        },
      ],
    },
  ],
};

const blogSection = {
  display: "medium", // Set it none to hide blogs or set it to hardcdoded for following blogs otherwise you can set it to medium to show your fetched(if you used your username for fetcher.mjs) medium blogs
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  blogs: [
    {
      url:
        "https://devzrico.zahidulsifat.me/how-to-set-up-a-tech-domain-for-github-pages-with-a-free-cloudflare-ssl-certificate",
      title:
        "How to set up a .tech domain for GitHub pages with a free Cloudflare SSL certificate",
      description:
        "Setup your .tech domain with a free Cloudflare SSL certificate",
    },
    {
      url: "https://devzrico.zahidulsifat.me/openai-web-app",
      title: "Get Started With Open AI Web App Development",
      description: "Startup Name Generator",
    },
  ],
};

const talkSection = {
  display: false, // Set it true to show talks
  title: "Talks",
  subtitle: "I love to share my limited knowledge and get a speaker badge",

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/",
    },
  ],
};

// Podcast Section

const podcastSection = {
  display: false, // Set it true to show your podcasts
  title: "Podcast",
  subtitle: "I love to talk about myself and technology",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo",
  ],
};

// Your tweets displayed in a iframe
const twitterUsername = "none"; // set it none to display no tweets

/****************               Education Page          ****************/

// Your profiles in Competitive Sites to be displayed in education page
const competitiveSites = {
  display: true, // Set it to true to show icons containing links to your profile in respective sites
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/zahidulsifat",
    },
    {
      siteName: "Udemy",
      iconifyClassname: "simple-icons:udemy",
      style: {
        color: "#a435f0",
      },
      profileLink: "https://www.udemy.com/user/md-zahidul-islam-sifat/",
    },
    /*{
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },*/
  ],
};

// Degrees received from institutions
const degrees = {
  display: true, // Set it false to hide degrees, defaults to true
  degrees: [
    {
      title: "International Islamic University Chittagong",
      subtitle:
        "Bachelor of Science in Electronic and Telecommunication Engineering",
      logo_path: "iiucLogo.png",
      alt_name: "IIUC",
      duration: "2019 - Present",
      descriptions: [
        "⚡ Research: An Improved Positioning System For 6G",
        "⚡ To Contribute in Future Localization and Sensing of Cellular Network",
      ],
      website_link: "https://iiuc.ac.bd/",
    },
    {
      title: "Feni Government College",
      subtitle: "Higher Secondary School Certificate",
      logo_path: "fgcLogo.png",
      alt_name: "FGC",
      duration: "2016 - 2018",
      descriptions: ["⚡ Science", "⚡ Completed my HSC through Cumilla Board"],
      website_link: "https://fgc.gov.bd/",
    },
    {
      title: "Feni Government Pilot High School",
      subtitle: "Secondary School Certificate",
      logo_path: "fgphsLogo.png",
      alt_name: "FGPHS",
      duration: "2014 - 2016",
      descriptions: ["⚡ Science", "⚡ Completed my SSC through Cumilla Board"],
      website_link: "https://fgphs.edu.bd/",
    },
  ],
};

// Certifications in education page
const certifications = {
  display: true, // Set it false to hide certifications, defaults to true
  certifications: [
    {
      title: "Advanced HTML5",
      subtitle: "XL Teachings",
      logo_path: "HTML5logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-e31f1022-74f8-4885-948d-b01c53dccbd1/",
      alt_name: "Udemy",
      color_code: "#e34c26",
    },
    {
      title: "Python Basic",
      subtitle: "Harishankaran K",
      logo_path: "Python.png",
      certificate_link: "https://www.hackerrank.com/certificates/15e00659936c",
      alt_name: "Hacker Rank",
      color_code: "#0C9D5899",
    },
    {
      title: "Graphics Design",
      subtitle: "Creative IT Certification",
      logo_path: "creativeitLogo.webp",
      certificate_link:
        "https://drive.google.com/file/d/19xBnf_nIaQRCzrQipDSf5svm6lB6YTYK/view",
      alt_name: "Creative IT",
      color_code: "#2A73CC",
    },
    {
      title: "Google Analytics Certification",
      subtitle: "Skillshop",
      logo_path: "google_analytics_logo.png",
      certificate_link:
        "https://skillshop.credential.net/fee9d886-245c-4b8f-9315-b8614da312cd",
      alt_name: "Google",
      color_code: "#ec2e33",
    },
    /*{
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },*/
  ],
};

/*******************          Experience Page          ****************/

const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "Gathering New Experiences Every Now and Then",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Executive",
          company: "Smart Technologies (BD) Ltd.",
          company_url: "https://smartbd.com/",
          logo_path: "stbl-frame.png",
          duration: "May 2024 - Present",
          location:
            "Jahir Smart Tower, 205/1 & 205/1/A, West Kafrul, Begum Rokeya Sharani, Taltola, Dhaka-1207",
          description:
            "B2B Channel Distribution, IT Product Service Management",
          color: "#0879bf",
        },
        {
          title: "Freelance Graphics Designer",
          company: "Fiverr",
          company_url: "https://www.fiverr.com/zahidulsifat",
          logo_path: "fiverrLogo.png",
          duration: "Jan 2020 – Present",
          /*location: "Ahmedabad, Gujarat",*/
          description: "Album Covers and Logos",
          color: "#fc1f20",
        },
        {
          title: "Secratary Technical",
          company: "ETE Research Society",
          company_url: "https://www.linkedin.com/company/etersiiuc/",
          logo_path: "etersLogo.png",
          duration: "May 2023 - Jan 2024",
          location: "FSE,IIUC Chittagong",
          description: "Acting Proctor of ETE Research Society",
          color: "#0879bf",
        },
        {
          title: "Teaching Assistant",
          company: "International Islamic University Chittagong",
          company_url: "https://iiuc.ac.bd",
          logo_path: "iiucLogo.png",
          duration: "Jan 2023 -Jan 2024 Present",
          location: "Chittagong, Bangladesh",
          description:
            "Teaching Database Management System Sessional at Department of Electronic and Telecommunication Engineering",
          color: "#9b1578",
        },
        {
          title: "Junior Android Developer",
          company: "XDA Developers",
          company_url:
            "https://forum.xda-developers.com/m/zahidulsifat.7862303/",
          logo_path: "xdaLogo.png",
          duration: "Nov 2017 - Dec 2020",
          /*location: "Ahmedabad, Gujarat",*/
          description:
            "XDA is a mobile software development community launched on 20 December 2002.I was mostly active on Samsung Galaxy S3 Neo Community and helped developed OS for this device. Such as SafetyNet Fixes, FlymeOS Five for Galaxy S3 Neo",
          color: "#fc1f20",
        },
      ],
    },
    /*{
      title: "Internships",
      experiences: [
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },*/
  ],
};

/********************              Hobbies         ********************/

const hobbies = {
  title: "Big Projects",
  hobbies: [
    {
      title: "Safety Net Fix",
      description: "Google's Safety Net Fix on Rooted Android Smartphones.",
      image_path: "SafetyNetFixLogo.png",
      url:
        "https://forum.xda-developers.com/t/fix-safety-net-cts-profile-false-on-custom-roms.3786849/",
    },
    {
      title: "Flyme Os Five Port For Galaxy S3 Neo",
      description:
        "Fixed Camera Detection, Offline Charging and Seandroid Enforcing ",
      image_path: "FlymeOS.png",
      url:
        "https://forum.xda-developers.com/t/flyme-5-1-12-23r-s3-neo-i9301l-i9300l.3755117/",
    },
    {
      title: "Portfolio Website",
      description:
        "My Portfolio Website made using HTML, CSS, JavaScript, with fixes and a refined UI.",
      image_path: "portfolio_website.PNG",
      url: "https://zahidulsifat.github.io",
    },
    /*{
      title: "Listening Music",
      description: "",
      image_path: "Music.png",
    },*/
  ],
};

/****************            OpenSource Page           ********************/

const openSource = {
  organisations: true, // Set it false to hide contributions in github organisations, defaults to true

  issues: false, // Set it false to hide your opened isssues, defaults to true

  pull_requests: true, // Set it false to hide your pull requests in github organisations, defaults to true

  issue_chart: true, // Set it false to hide issue chart, defaults to true

  pr_chart: true, // Set it false to hide pull requests chart, defaults to true
};

/****************            Projects Page           ********************/

// Projects (Github) Section of Projects Page
const projects = {
  display: true, // Set it false to hide your pinned projects in github, defaults to true
  title: "Project",
  description:
    "My projects are mostly open source. Love to enhance every project to the peak!",
  avatar_image_path: "projects_image.svg",
};

// Publication Section of Projects Page
const publications = {
  display: true, // Set it true to show publications
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
  publications: {
    data: [
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
        name: "An Improved Positioning System For 6G Cellular Network",
        createdAt: "2023-12-15T16:26:54Z",
        description:
          "2023 26th International Conference on Computer and Information Technology (ICCIT) ",
        url: "https://doi.org/10.1109/ICCIT60459.2023.10441118",
      },
      /*{
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description:
          "Paper Written on Artificial Intelligence published in xyz ",
        url: "https://example.com",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description:
          "Paper Written on Artificial Intelligence published in xyz ",
        url: "https://example.com",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description:
          "Paper Written on Artificial Intelligence published in xyz ",
        url: "https://example.com",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description:
          "Paper Written on Artificial Intelligence published in xyz ",
        url: "https://example.com",
      },*/
    ],
  },
};

/*******************          Contact Page          ****************/

const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile-pic.jpg",
    description:
      "I would love to hear from you. I am available on all major social platform",
  },
  blogSection: {
    title: "Design Showcase Web",
    subtitle: "Check out my design showcase web!",
    link: "https://design.zahidulsifat.me",
    avatar_image_path: "fullstack.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Chittagong, Bangladesh",
    avatar_image_path: "address_image.svg",
    location_map_link:
      "https://www.google.com/maps/@23.0118874,91.3752197,13.75z",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

// Send a message in emailjs account
const contactMessage = {
  display: true, // Set it false if you don't have emailjs account or you don't want message, defaults to true
  emailjsUserID: "AFnHU7IlXB9yaM_lk",
  templateID: "template_u7jusue",
  serviceID: "service_fx477da",
};

export {
  pageEnabled,
  routerValue,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  techStack,
  competitiveSites,
  degrees,
  certifications,
  experience,
  hobbies,
  podcastSection,
  bigProjects,
  talkSection,
  openSource,
  projects,
  publications,
  blogSection,
  contactPageData,
  contactMessage,
  twitterUsername,
  splashScreen,
};
